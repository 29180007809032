import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, useMergeRefs } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-cool-inview';
export function VirtualList({ items, columnCount = 1, children, renderItem, preloadCount = 0, ...gridProps }) {
    const [rowHeight, setRowHeight] = useState(0);
    const firstRowRef = useRef(null);
    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            const entry = entries[0];
            setRowHeight(entry.contentRect.height);
        });
        if (firstRowRef.current) {
            resizeObserver.observe(firstRowRef.current);
        }
        return () => {
            resizeObserver.disconnect();
        };
    }, []);
    const [firstRow, rows] = useMemo(() => {
        const r = [];
        for (let i = 0; i < items.length; i += columnCount) {
            const chunk = items.slice(i, i + columnCount);
            r.push({ id: i, items: chunk });
        }
        return [r[0], r.slice(1)];
    }, [items, columnCount]);
    return (_jsxs(_Fragment, { children: [_jsx(VirtualListRow, { alwaysRender: true, ref: firstRowRef, ...gridProps, children: firstRow?.items && firstRow?.items.length > 0
                    ? firstRow?.items.map((item, i) => renderItem(item))
                    : null }), preloadCount > 0 &&
                rows.slice(0, preloadCount).map((row, index) => (_jsx(VirtualListRow, { alwaysRender: true, rowHeight: rowHeight, ...gridProps, children: row.items.map((item, i) => renderItem(item)) }, `${row.id}-row-start`))), rowHeight > 0 &&
                rows.slice(preloadCount).map((row, index) => (_jsx(VirtualListRow, { rowHeight: rowHeight, ...gridProps, children: row.items.map((item, i) => renderItem(item)) }, `${row.id}-row-start`)))] }));
}
export const VirtualListRow = forwardRef(({ children, rowHeight, inViewOptions, alwaysRender, ...rest }, ref) => {
    const { observe, inView } = useInView(inViewOptions);
    const mergedRefs = useMergeRefs(ref, observe);
    return (_jsx(Box, { ref: mergedRefs, minHeight: rowHeight, ...rest, children: alwaysRender || inView ? children : null }));
});
