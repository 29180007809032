var __jsx = React.createElement;
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { SimpleGrid } from '@playful/design_system';
import { VirtualList } from '@playful/components';
import React, { Fragment } from 'react';
export var ProjectList = function ProjectList(_ref) {
  var _ref2;
  var projectInfos = _ref.projectInfos,
    _ref$preloadCount = _ref.preloadCount,
    preloadCount = _ref$preloadCount === void 0 ? 0 : _ref$preloadCount,
    columns = _ref.columns,
    columnCount = _ref.columnCount,
    children = _ref.children,
    isFeatured = _ref.isFeatured;
  var defaultColumns = {
    base: 2,
    xs: 2,
    sm: 2,
    md: 3,
    lg: 5,
    xl: 6
  };
  var defaultColumnCount = useBreakpointValue(defaultColumns);
  var virtualColumns = columns !== null && columns !== void 0 ? columns : defaultColumns;
  var virtualColumnCount = (_ref2 = columnCount !== null && columnCount !== void 0 ? columnCount : defaultColumnCount) !== null && _ref2 !== void 0 ? _ref2 : 5;
  var featuredColumns = {
    base: 1,
    xs: 1,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4,
    '2xl': 5
  };
  var featuredColumnCount = useBreakpointValue(featuredColumns);
  return __jsx(Box, {
    py: 4
  }, __jsx(VirtualList, {
    as: SimpleGrid,
    pb: 6,
    columns: isFeatured ? featuredColumns : virtualColumns,
    columnCount: isFeatured ? featuredColumnCount : virtualColumnCount,
    gap: {
      base: 4,
      xs: 4,
      sm: 4,
      md: 6,
      lg: 6,
      xl: 6
    },
    items: projectInfos,
    preloadCount: preloadCount,
    renderItem: function renderItem(info) {
      return __jsx(Fragment, {
        key: info.id + '-' + info.version
      }, children(info));
    }
  }));
};