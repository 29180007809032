var __jsx = React.createElement;
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { Skeleton } from '@chakra-ui/react';
import { Box, MotionBox, ThumbnailPreview, breakpoints } from '@playful/design_system';
import times from 'lodash/times';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PaginatedCarousel } from '../PaginatedCarousel';
export function TemplateShowcase(_ref) {
  var _ref$_key = _ref._key,
    _key = _ref$_key === void 0 ? '*' : _ref$_key,
    isCarousel = _ref.isCarousel,
    projects = _ref.projects,
    _ref$projectInfos = _ref.projectInfos,
    projectInfos = _ref$projectInfos === void 0 ? {} : _ref$projectInfos,
    Card = _ref.renderCard;
  var _useState = useState(0),
    containerWidth = _useState[0],
    setContainerWidth = _useState[1];

  // How many thumbnails to fit on a row
  var pageSize = useMemo(function () {
    if (containerWidth < breakpoints.lg) return 2;
    if (containerWidth < breakpoints.xl) return 4;
    if (containerWidth < breakpoints['2xl']) return 4;
    return 5;
  }, [containerWidth]);

  // Gap in between thumbnails
  var gap = useMemo(function () {
    if (containerWidth < breakpoints.md) return 16;
    return 24;
  }, [containerWidth]);
  var eachSize = useMemo(function () {
    return (containerWidth - (pageSize - 1) * gap) / pageSize;
  }, [pageSize, containerWidth, gap]);
  var containerRef = useRef();
  useEffect(function () {
    var observer = new ResizeObserver(function (entries) {
      var _iterator = _createForOfIteratorHelper(entries),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var entry = _step.value;
          var width = entry.contentRect.width;
          setContainerWidth(width);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    });
    var container = containerRef.current;
    if (container) {
      observer.observe(container);
    }
    return function () {
      if (container) {
        observer.unobserve(container);
      }
    };
  }, []);
  return __jsx(MotionBox, {
    ref: containerRef,
    w: "100%",
    position: "relative",
    initial: {
      top: '-16px',
      opacity: 0
    },
    animate: {
      top: '0px',
      opacity: 1
    },
    transition: {
      type: 'spring',
      mass: 1,
      stiffness: 100,
      damping: 35
    }
  }, isCarousel ? __jsx(PaginatedCarousel, {
    pageSize: pageSize,
    _key: _key,
    gap: gap,
    eachSize: eachSize
  }, !projects ? times(pageSize, function (idx) {
    return __jsx(ThumbnailPreview, {
      key: idx,
      Thumbnail: __jsx(Skeleton, {
        width: eachSize,
        height: "100%"
      })
    });
  }) : projects === null || projects === void 0 ? void 0 : projects.map(function (p) {
    return __jsx(Card, {
      key: p.id,
      info: projectInfos[p.id],
      videoUrl: p.videoUrl
    });
  })) : __jsx(Box, {
    display: "flex",
    gap: "".concat(gap, "px"),
    width: "100%",
    flexWrap: "wrap"
  }, !projects ? times(pageSize, function (idx) {
    return __jsx(Box, {
      width: eachSize,
      key: idx
    }, __jsx(ThumbnailPreview, {
      Thumbnail: __jsx(Skeleton, {
        width: eachSize,
        height: "100%"
      })
    }));
  }) : projects.map(function (p) {
    return __jsx(Box, {
      width: eachSize,
      key: p.id
    }, __jsx(Card, {
      info: projectInfos[p.id],
      videoUrl: p.videoUrl
    }));
  })));
}