var __jsx = React.createElement;
import { Skeleton } from '@chakra-ui/react';
import { Box, MotionBox, Tabs } from '@playful/design_system';
import React from 'react';
import { StickyHeader } from './StickyHeader';
export function TaggedProjectsHeader(_ref) {
  var categories = _ref.categories,
    wrap = _ref.wrap,
    isSticky = _ref.isSticky,
    isLoading = _ref.isLoading;
  return __jsx(MotionBox, {
    initial: {
      opacity: 0
    },
    animate: {
      opacity: 1
    },
    transition: {
      type: 'spring',
      mass: 1,
      stiffness: 100,
      damping: 35
    }
  }, isLoading ? __jsx(Box, {
    display: "flex",
    gap: 3,
    h: "60px",
    alignItems: "center"
  }, categories.map(function (c) {
    return __jsx(Skeleton, {
      key: c.title,
      height: "14px",
      width: "74px"
    });
  })) : __jsx(StickyHeader, {
    isSticky: isSticky,
    categories: categories,
    wrap: wrap
  }));
}
export function TaggedProjectsTabs(_ref2) {
  var onTabChange = _ref2.onTabChange,
    tabIndex = _ref2.tabIndex,
    children = _ref2.children;
  return __jsx(Tabs, {
    size: 'sm',
    onChange: function onChange(index) {
      return onTabChange(index);
    },
    variant: "solid",
    index: tabIndex,
    isLazy: true
  }, children);
}