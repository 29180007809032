import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import { Tab, TabList } from '@playful/design_system';
import { formatEventString, triggerCustomEvent } from '@playful/telemetry';
import React, { useEffect, useRef, useState } from 'react';
import { HEADER_HEIGHT } from '../../layouts/MainLayout';
import { useProjectTemplates } from '../ProjectTemplates';
export var StickyHeader = function StickyHeader(_ref) {
  var isSticky = _ref.isSticky,
    categories = _ref.categories,
    _ref$wrap = _ref.wrap,
    wrap = _ref$wrap === void 0 ? false : _ref$wrap;
  var _useProjectTemplates = useProjectTemplates(),
    containerPadding = _useProjectTemplates.containerPadding;
  var stickyBarObserver = useRef();
  var stickyBarRef = useRef();
  var _useState = useState(false),
    stickyIsActive = _useState[0],
    setStickyIsActive = _useState[1];
  useEffect(function () {
    var observer = stickyBarObserver.current;
    var stickyEl = stickyBarRef.current;
    return function () {
      if (observer && stickyEl) observer.unobserve(stickyEl);
    };
  }, []);

  // TODO: Tabs scroll
  return __jsx(React.Fragment, null, __jsx("div", {
    ref: function ref(el) {
      if (el && isSticky) {
        stickyBarRef.current = el;
        stickyBarObserver.current = new IntersectionObserver(function (_ref2) {
          var _ref3 = _slicedToArray(_ref2, 1),
            e = _ref3[0];
          return setStickyIsActive(!e.isIntersecting);
        }, {
          threshold: [1],
          rootMargin: "-".concat(HEADER_HEIGHT, "px 0px 0px 0px")
        });
        stickyBarObserver.current.observe(el);
      }
    }
  }), __jsx(TabList, {
    position: isSticky ? 'sticky' : 'static',
    top: containerPadding.top + 'px',
    left: "0",
    zIndex: isSticky && stickyIsActive ? 'sticky' : 'auto',
    backgroundColor: "white",
    py: 4,
    boxShadow: stickyIsActive ? 'md' : 'none',
    mx: stickyIsActive ? '-' + containerPadding.left + 'px' : 0,
    pl: stickyIsActive ? containerPadding.left + 'px' : 0,
    overflowX: "auto",
    flexWrap: wrap ? 'wrap' : 'nowrap'
  }, categories.map(function (c) {
    return __jsx(Tab, {
      key: c.title,
      whiteSpace: "nowrap",
      onClick: triggerCustomEvent('templatepicker-label-click', {
        name: formatEventString(c.title)
      })
    }, c.title);
  })));
};