import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import { RouteLink } from '@playful/design_system';
import React from 'react';
export function ViewAll(props) {
  return __jsx(RouteLink, _extends({
    lineHeight: 'shorter',
    size: 'md',
    fontWeight: 'bold'
  }, props), "view all");
}