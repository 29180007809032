import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import { Box, forwardRef } from '@chakra-ui/react';
import React from 'react';

// This is a workaround for preventing bouncing while scrolling in safari. Because safari
// does not, as of today, respect `overscroll-behavior: none`, we add `overflow: hidden` to
// the body, and make *only* the content area scrollable. This prevents the bounce effect on
// the body; while there is still a bounce effect within this container, it's less
// noticeable, and we prevent it at the workbench level, which is most important.
//
// TODO: when safari supports `overscroll-behavior`, we can simplify a lot.

export var HEADER_HEIGHT = 48;
var ContentOverflow = forwardRef(function (props, ref) {
  return __jsx(Box, _extends({
    ref: ref,
    overflow: "auto",
    height: "100%"
  }, props));
});

/**
 * The main layout, as responsive, composable elements (vs HOC).
 */
export function useMainLayout() {
  return {
    Wrapper: Box,
    Content: ContentOverflow
  };
}