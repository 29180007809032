var __jsx = React.createElement;
import { Text } from '@playful/design_system';
import { customEvent } from '@playful/telemetry';
import React, { useCallback, useEffect, useState } from 'react';
import { TaggedProjectsHeader, TaggedProjectsTabs } from './TaggedProjectsTabs';
import { TemplateShowcase } from './TemplateShowcase';
export function UseCaseTemplatePicker(_ref) {
  var _categories$tabIndex, _selectedCategory$pro;
  var category = _ref.category,
    onCategoryChange = _ref.onCategoryChange,
    isCarousel = _ref.isCarousel,
    renderCard = _ref.renderCard,
    isSticky = _ref.isSticky,
    origin = _ref.origin,
    title = _ref.title,
    _ref$categories = _ref.categories,
    categories = _ref$categories === void 0 ? [] : _ref$categories,
    projectInfos = _ref.projectInfos;
  var handleCategoryChange = useCallback(function (category) {
    onCategoryChange === null || onCategoryChange === void 0 || onCategoryChange(category);
  }, [onCategoryChange]);

  // Filter out categories prefixed with "_" (e.g. _interactives)
  var _useState = useState(category ? categories.findIndex(function (cat) {
      return cat.slug === category;
    }) : 1),
    tabIndex = _useState[0],
    setTabIndex = _useState[1];
  var onTabChange = useCallback(function (index) {
    var category = categories[index];
    setTabIndex(index);
    handleCategoryChange(category.slug);
  }, [handleCategoryChange, categories]);
  useEffect(function () {
    customEvent('templatepicker-load', {
      location: origin
    });
  }, [origin]);
  var selectedCategory = (_categories$tabIndex = categories[tabIndex]) !== null && _categories$tabIndex !== void 0 ? _categories$tabIndex : {};
  var activeProjects = (_selectedCategory$pro = selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.projects) !== null && _selectedCategory$pro !== void 0 ? _selectedCategory$pro : [];
  return __jsx("div", null, title && __jsx(Text, {
    as: "h3",
    fontWeight: "medium"
  }, title), __jsx(TaggedProjectsTabs, {
    onTabChange: onTabChange,
    tabIndex: tabIndex
  }, __jsx(TaggedProjectsHeader, {
    isLoading: !projectInfos,
    categories: categories,
    isSticky: isSticky
  }), __jsx(TemplateShowcase, {
    isCarousel: !!isCarousel,
    renderCard: renderCard,
    projects: activeProjects,
    projectInfos: projectInfos,
    _key: selectedCategory.title
  })));
}