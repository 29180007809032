var __jsx = React.createElement;
import { Box, ChevronLeftIcon, ChevronRightIcon, IconButton } from '@playful/design_system';
import React, { Children, useEffect, useState } from 'react';
export function PaginatedCarousel(_ref) {
  var children = _ref.children,
    pageSize = _ref.pageSize,
    _eachSize = _ref.eachSize,
    _key = _ref._key,
    gap = _ref.gap;
  var eachSizeMin = 200;
  var eachSizeMax = 300;
  var eachSize = Math.min(Math.max(_eachSize, eachSizeMin), eachSizeMax);
  var childrenArray = Children.toArray(children);
  var _useState = useState(0),
    page = _useState[0],
    setPage = _useState[1];
  var numberOfPages = Math.ceil(children.length / pageSize);
  var left = page === 0 ? 0 : page * (eachSize + gap) * pageSize * -1;
  useEffect(function () {
    setPage(0);
  }, [_key]);
  return __jsx(Box, {
    w: "100%"
  }, __jsx(Box, {
    pos: 'absolute',
    w: "100%",
    overflowX: {
      base: 'auto',
      lg: 'clip'
    },
    overflowY: "visible",
    left: 0,
    right: 0,
    zIndex: 'sticky'
  }, __jsx(Box, {
    w: (eachSize + gap) * children.length,
    left: {
      base: '0px',
      lg: left + 'px'
    },
    pos: 'relative',
    transition: "left 0.8s",
    overflow: eachSize > 0 ? 'visible' : 'hidden',
    whiteSpace: "nowrap"
  }, Children.map(childrenArray, function (child) {
    return __jsx(Box, {
      display: "inline-block",
      w: eachSize,
      mr: "".concat(gap, "px")
    }, child);
  }))), __jsx(Box, {
    pos: "relative"
  }, __jsx(IconButton, {
    "aria-label": "previous templates",
    "aria-hidden": "true",
    size: "lg",
    icon: __jsx(ChevronLeftIcon, null),
    pos: "absolute",
    top: 'calc(50% - 24px)',
    left: '-24px',
    zIndex: 'sticky',
    shadow: 'md',
    _focus: {
      shadow: 'md'
    },
    colorScheme: "white",
    variant: "solid",
    onClick: function onClick() {
      setPage(page - 1);
    },
    hidden: pageSize === 2 || page === 0
  }), __jsx(IconButton, {
    "aria-label": "previous templates",
    "aria-hidden": "true",
    size: "lg",
    icon: __jsx(ChevronRightIcon, null),
    pos: "absolute",
    top: 'calc(50% - 24px)',
    right: '-24px',
    zIndex: 'sticky',
    shadow: 'md',
    _focus: {
      shadow: 'md'
    },
    colorScheme: "white",
    variant: "solid",
    onClick: function onClick() {
      setPage(page + 1);
    },
    hidden: pageSize === 2 || page + 1 === numberOfPages
  }), __jsx(Box, {
    visibility: "hidden",
    w: eachSize
  }, children[0])));
}