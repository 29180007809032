import _toConsumableArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
var __jsx = React.createElement;
import { ProjectList } from '../components/ProjectGrids';
import { UserCard } from './ProjectThumbnailCard';
import { useUserProjects } from '../hooks/useProjects';
import { useUserContext } from '../user/UserContext';
import { FEATURE_PROJECT_TAGS } from '@playful/api';
import React, { useEffect, useMemo, useState } from 'react';
import { getDatabase, off, onValue, ref } from 'firebase/database';
import { TaggedProjectsHeader, TaggedProjectsTabs } from '../components/TemplatePickers';
var categories = [{
  title: 'all',
  slug: '*',
  filter: function filter(_) {
    return true;
  }
}, {
  title: 'unpublished',
  slug: 'unpublished',
  filter: function filter(project) {
    return !project.published;
  }
}, {
  title: 'published',
  slug: 'published',
  filter: function filter(project) {
    return !!project.published;
  }
}, {
  title: 'remixable',
  slug: 'remixable',
  filter: function filter(project) {
    return project.permissions.allowRemixing;
  }
}, {
  title: 'shared with community',
  slug: 'shared',
  filter: function filter(project) {
    return project.permissions.showInGallery;
  }
}];
export function MyProjectsGrid(_ref) {
  var _onBulkSelection = _ref.onBulkSelection,
    isBulkModeEnabled = _ref.isBulkModeEnabled,
    selectedInfos = _ref.selectedInfos;
  var _useUserContext = useUserContext(),
    user = _useUserContext.user,
    hasFlag = _useUserContext.hasFlag;
  var _useUserProjects = useUserProjects(user.id, undefined, {
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateIfStale: false
    }),
    projectInfos = _useUserProjects.projectInfos,
    mutateUserProjects = _useUserProjects.mutate;
  var _useState = useState(0),
    tabIndex = _useState[0],
    setTabIndex = _useState[1];
  useEffect(function () {
    if (!user.id) return;
    var userPushRef = ref(getDatabase(), "userPush/".concat(user.id, "/user_projects"));
    var onProjectsValue = function onProjectsValue() {
      return mutateUserProjects === null || mutateUserProjects === void 0 ? void 0 : mutateUserProjects();
    }; // trigger a revalidation

    onValue(userPushRef, onProjectsValue);
    return function () {
      off(userPushRef, 'value', onProjectsValue);
    };
  }, [user.id, mutateUserProjects]);

  // these are provided in no particular order, so sort by most recent
  var sortedInfosByRecent = useMemo(function () {
    return projectInfos.slice().sort(function (a, b) {
      return new Date(b.modified).getTime() - new Date(a.modified).getTime();
    });
  }, [projectInfos]);
  var allCategories = useMemo(function () {
    var all = categories.slice();

    // Add all the tags as categories, if the feature is enabled
    if (hasFlag(FEATURE_PROJECT_TAGS)) {
      projectInfos.forEach(function (project) {
        if (project.tags) {
          Object.keys(project.tags).forEach(function (tag) {
            if (!tag) return;
            // Remove the order from the tag
            var tagName = tag.toLowerCase().split(':')[0];
            if (!all.find(function (cat) {
              return cat.slug === tagName;
            })) {
              all.push({
                title: tagName,
                slug: tagName,
                filter: function filter(project) {
                  return !!project.tags && !!Object.keys(project.tags).find(function (t) {
                    // Check if the tag includes the `tag` in 'tag:order'; e.g. `gallery_featured:21`
                    return t.toLowerCase().includes(tagName);
                  });
                }
              });
            }
          });
        }
      });
    }
    var sorted = all
    // remove categoies that are already in the standard list
    .filter(function (cat) {
      return !categories.find(function (c) {
        return c.slug === cat.slug;
      });
    })
    // sort the non-standard categories alphabetically
    .sort(function (a, b) {
      return a.title.localeCompare(b.title);
    });

    // Prepend the standard categories to the sorted list
    return [].concat(categories, _toConsumableArray(sorted));
  }, [hasFlag, projectInfos]);
  var projectsInCategories = useMemo(function () {
    return allCategories.reduce(function (acc, cat) {
      acc[cat.slug] = sortedInfosByRecent.filter(cat.filter);
      return acc;
    }, {});
  }, [allCategories, sortedInfosByRecent]);
  var categoriesWithProjects = useMemo(function () {
    return allCategories.filter(function (cat) {
      return !!projectsInCategories[cat.slug].length;
    });
  }, [allCategories, projectsInCategories]);

  // the tabIndex should never exceed the number of categories with projects.
  // this can happen if a user has the last tab selected and removes the last project
  // from it, causing it to be removed. This prevents a bad state from occurring before
  // the useEffect below can catch it.
  var activeTabIdx = Math.min(categoriesWithProjects.length - 1, tabIndex);
  var activeCategory = categoriesWithProjects[activeTabIdx];
  useEffect(function () {
    if (tabIndex !== activeTabIdx) return setTabIndex(activeTabIdx);

    // catch-all. this probably won't happen, but just in case.
    if (!activeCategory) setTabIndex(0);
  }, [activeTabIdx, tabIndex, activeCategory]);

  // no projects in any category, so don't show anything
  if (!projectInfos.length) return null;
  return __jsx(TaggedProjectsTabs, {
    onTabChange: setTabIndex,
    tabIndex: activeTabIdx
  }, __jsx(TaggedProjectsHeader, {
    isLoading: !projectsInCategories,
    categories: categoriesWithProjects,
    wrap: hasFlag(FEATURE_PROJECT_TAGS)
  }), __jsx(ProjectList, {
    projectInfos: projectsInCategories[activeCategory.slug]
  }, function (info) {
    return __jsx(UserCard, {
      onBulkSelection: function onBulkSelection() {
        return _onBulkSelection(info);
      },
      isBulkSelected: selectedInfos.has(info.id),
      isBulkModeEnabled: isBulkModeEnabled,
      fallbackData: info,
      projectId: info.id
    });
  }));
}