import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["title"];
var __jsx = React.createElement;
import { Text } from '@playful/design_system';
import React from 'react';
import { TemplateShowcase } from './TemplateShowcase';
export function CarouselTemplatePicker(_ref) {
  var title = _ref.title,
    props = _objectWithoutProperties(_ref, _excluded);
  return __jsx("div", null, title && __jsx(Text, {
    as: "h3",
    fontWeight: "medium",
    mb: 4
  }, title), __jsx(TemplateShowcase, _extends({
    isCarousel: true
  }, props)));
}